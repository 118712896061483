























import CallControlsStrip from '@/components/Calls/ActiveCall/CallControlsStrip/index.vue'
import CallDetailsHeader from '@/components/Calls/ActiveCall/TheCallMd/CallDetails/CallDetailsHeader/index.vue'
import { ControlFunctionType } from '@/components/Calls/ActiveCall/UniversalCallControl/models'
import MoveMixin from '@/components/Modals/FileViewer/Views/MoveImageMixin'
import { callsLogger } from '@/loggers'
import { activeCallStore } from '@/store'
import { ActiveCall } from '@/store/modules/activeCall/models'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import Wrapper from './Wrapper.vue'

@Component({
  components: {
    CallDetailsHeader,
    CallControlsStrip,
    Wrapper,
  },
  mixins: [MoveMixin],
})
export default class TheCallSm extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  // required for move mixin
  @Ref() readonly subject!: HTMLDivElement
  toggleEvents!: (toggle: boolean) => any

  x!: number | null
  y!: number | null
  boundsMargin = 8
  moveBounds = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  }

  width = 312
  height = 136

  callControls: ControlFunctionType[] = ['mute-audio', 'mute-video', 'volume']
  parentResizeObserver: ResizeObserver | null = null
  isDragging: boolean | null = null
  dragDelay: number | undefined = undefined

  @Watch('x')
  @Watch('y')
  onDragging () {
    this.isDragging = true
    clearTimeout(this.dragDelay)
    this.dragDelay = setTimeout(() => {
      this.isDragging = false
    }, 500)
  }

  private endCall (): void {
    activeCallStore.actions.endCall()
  }

  private created () {
    this.x = this.y = null
    this.parentResizeObserver = new ResizeObserver(this.resizeHandler)
  }

  private mounted () {
    this.resizeHandler()
    this.toggleEvents(true)
    this.attachParentResizeObserver()
  }

  private beforeDestroy () {
    if (this.parentResizeObserver) {
      this.parentResizeObserver.disconnect()
      this.parentResizeObserver = null
    }
  }

  private attachParentResizeObserver (): void {
    callsLogger.log('Attaching call SM parent resize observer...')

    const o = this.parentResizeObserver
    if (!o) {
      callsLogger.error('No resize observer in mounted. Could be problems with positioning.')
      return
    }

    const p = this.$el.parentElement
    if (!p) {
      callsLogger.error('No parent element in mounted. Can not attach observer.')
      return
    }
    o.observe(p)

    callsLogger.debug('Resize observer attached.')
  }

  private resizeHandler () {
    const { parentElement: parent } = this.$el
    if (!parent) return

    const boundsRect = parent.getBoundingClientRect()

    this.moveBounds = {
      minX: this.boundsMargin,
      maxX: boundsRect.width - this.boundsMargin - this.width,
      minY: this.boundsMargin,
      maxY: boundsRect.height - this.boundsMargin - this.height,
    }

    if (this.x === null || this.y === null) {
      this.x = this.moveBounds.maxX
      this.y = this.moveBounds.maxY
      return
    }

    const { minX, maxX, minY, maxY } = this.moveBounds
    this.x = Math.min(maxX, Math.max(minX, this.x))
    this.y = Math.min(maxY, Math.max(minY, this.y))
  }
}
