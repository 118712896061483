import { isChildOf } from '@/utils/DOM'

export default {
  data () {
    return {
      moveState: 0,
      x: 0,
      y: 0,
    }
  },
  created () {
    this.shiftX = 0
    this.shiftY = 0

    this.events = {
      mousedown: event => {
        let { subject } = this.$refs
        if (subject.$el) subject = subject.$el

        const { target } = event
        if (subject !== target && !isChildOf(target, subject)) return

        const { x, y } = subject.getBoundingClientRect()
        this.shiftX = event.pageX - (x + pageXOffset)
        this.shiftY = event.pageY - (y + pageYOffset)

        this.previousX = event.pageX - this.shiftX - this.x
        this.previousY = event.pageY - this.shiftY - this.y

        this.moveState = 2
      },
      mouseup: () => {
        this.moveState = 1
      },
      mousemove: event => {
        if (this.moveState !== 2) return

        const currentX = event.pageX - this.shiftX
        const currentY = event.pageY - this.shiftY

        const x = currentX - this.previousX
        const y = currentY - this.previousY

        if (this.moveBounds) {
          const { minX, maxX, minY, maxY } = this.moveBounds

          this.x = Math.min(maxX, Math.max(minX, x))
          this.y = Math.min(maxY, Math.max(minY, y))

          return
        }

        this.x = x
        this.y = y
      },
    }
  },
  beforeDestroy () {
    this.toggleEvents(false)
  },
  methods: {
    toggleEvents (add) {
      const prefix = add ? 'add' : 'remove'
      Object.keys(this.events).forEach(name => {
        window[`${prefix}EventListener`](name, this.events[name])
      })
    },
  },
}
